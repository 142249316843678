<template>
  <div class="cont">
    <div class="head-box">
      <div class="title-box">
        <div class="title">课节详情</div>
      </div>
      <div class="top-box">
        <div>课节名称：{{ lessonInfo.name }}</div>
        <div>课程名称：{{ courseInfo.name }}</div>
        <div>上课时间：{{ lessonInfo.beginTime }} - {{lessonInfo.endTime}}</div>
        <!-- 时长：{{ duration }}分 -->
        <div>
          <!-- <button class="btn csp" @click="$router.push({path:'/asynclive',query:{id:lessonInfo.id}})">观看回放</button> -->
          <button class="btn csp" @click="$router.push({path:'/newasyncLive',query:{id:lessonInfo.id}})">观看回放</button>
        </div>
      </div>
    </div>
    <div class="bot-box">
      <div class="title-box">
        <div class="title">观看数据</div>
      </div>
      <!-- <div class="bot-block">
        <div class="box-top">
          <select class="select" name="pets" id="pet-select">
            <option value>角色</option>
          </select>
          <select class="select" name="pets" id="pet-select">
            <option value>地区</option>
          </select>
          <select class="select" name="pets" id="pet-select">
            <option value>学校</option>
          </select>
          <select class="select" name="pets" id="pet-select">
            <option value>观看终端</option>
          </select>
          时间
          <el-time-picker
            is-range
            v-model="shijianduan"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            size="small"
            style="width:188px"
          ></el-time-picker>
          <el-input
            placeholder="请输入姓名/学校"
            size="small"
            style="margin-left:23px;width:185px"
            suffix-icon="el-icon-search"
            v-model="search"
          ></el-input>
        </div>
        <div class="box-bot">
          <img
            style="vertical-align: middle;"
            src="@/assets/images/course/icon-rect.png"
            alt
          />
          <span style="margin-left:12px;margin-right:33px;">下载表格</span>
        </div>
      </div> -->
      <div class="mid-bot">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column align="center" prop="id" label="序号" style="background:#2373EC"></el-table-column>
          <el-table-column align="center" prop="name" label="学员姓名"></el-table-column>
          <el-table-column align="center" prop="no" label="编号"></el-table-column>
          <el-table-column align="center" prop="rolename" label="角色"></el-table-column>
          <el-table-column align="center" prop="phone" label="手机号"></el-table-column>
          <el-table-column align="center" prop="area" label="地区"></el-table-column>
          <el-table-column align="center" prop="school" label="学校"></el-table-column>
          <el-table-column align="center" prop="entertime" label="首次进入时间"></el-table-column>
          <el-table-column align="center" prop="watchtime" label="累计观看时长"></el-table-column>
          <el-table-column align="center" prop="watchterminal" label="观看终端"></el-table-column>
          <el-table-column align="center" prop="IP" label="观看者IP"></el-table-column>
        </el-table>
        <pagination :total="pages.total" :page.sync="pages.currentPage" :limit.sync="pages.pageSize"
          :pageSizes="[5,10,15]" @pagination="handlePageChange">
        </pagination>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  export default {
    data() {
      return {
        className: "短文朗读",
        courseName: "普通话培训",
        time: "2020.06.13 8:30-10:30",
        duration: "120",
        search: "",
        shijianduan: [],
        activeName: "first",
        tableData: [],
        lessonInfo: {},
        courseInfo: {},
        pages: { //分页
          currentPage: 1,
          pageSize: 5,
          total: 0,
        },
      }
    },
    methods: {
      handleClick(tab, event) {
        // console.log(tab, event);
      },
      // 翻页
      handlePageChange(data) {
        this.pages.currentPage = data.pageIndex
        this.pages.pageSize = data.pageSize
      },
      // 获取课节信息
      async getLessonInfo() {
        let id = this.$route.params.id;
        let resData = await this.$Api.Course.getLesson(id);
        //  console.log(resData);
        this.lessonInfo = resData.data
        Vue.set(this.lessonInfo, 'beginTime', this.dayjs(this.lessonInfo.lessonsBeginTime * 1000).format(
          'YYYY-MM-DD hh:mm'))
        Vue.set(this.lessonInfo, 'endTime', this.dayjs(this.lessonInfo.lessonsEndTime * 1000).format('hh:mm'))
        this.getCourseInfo(resData.data.courseProjectId)
      },
      async getCourseInfo(id) {
        let resData = await this.$Api.Course.getCourseInfo(id);
        this.courseInfo = resData.data
        // console.log(resData);
      },
    },
    mounted() {
      this.getLessonInfo();
      // console.log(new Date());
    }
  };
</script>

<style lang="less" scoped>
  // @import "./less/table.less";

  /*媒体查询*/
  /*当页面大于1200px 时，大屏幕，主要是PC 端*/
  @media (min-width: 1200px) {
    .head-box {
      box-shadow: 0px 5px 29px 0px rgba(22, 115, 255, 0.16);
      border-radius: 10px;
      width: 1200px;
      margin: 0px auto 37px;
      background: #ffffff;
      padding-bottom: 24px;
    }

    .bot-box {
      box-shadow: 0px 5px 29px 0px rgba(22, 115, 255, 0.16);
      border-radius: 10px;
      width: 1200px;
      margin: 0 auto 37px;
      background: #ffffff;
      padding-bottom: 24px;
    }
  }

  /*在992 和1199 像素之间的屏幕里，中等屏幕，分辨率低的PC*/
  @media (min-width: 992px) and (max-width: 1199px) {
    .head-box {
      box-shadow: 0px 5px 29px 0px rgba(22, 115, 255, 0.16);
      border-radius: 10px;
      width: 950px;
      margin: 0px auto 37px;
      background: #ffffff;
      padding-bottom: 24px;
    }

    .bot-box {
      box-shadow: 0px 5px 29px 0px rgba(22, 115, 255, 0.16);
      border-radius: 10px;
      width: 950px;
      margin: 0 auto 37px;
      background: #ffffff;
      padding-bottom: 24px;
    }
  }

  .bread-crumb {
    width: 1200px;
    margin: 0 auto;
    line-height: 98px;
    font-size: 14px;
    color: #5a5a5a;
  }

  .el-tabs__header {
    line-height: 77px;

    .el-tabs__item {
      padding: 0 25px !important;
      font-size: 20px;
      font-weight: bold;
      color: #9c9b9b;
    }

    .el-tabs__item:hover {
      color: #2373ec;
    }

    .el-tabs__item.is-active {
      color: #2373ec;
    }
  }

  .el-tabs__active-bar {
    height: 5px;
    border-radius: 3px;
    background-color: #2373ec;
  }

  .title-box {
    height: 77px;
    margin-bottom: 20px;
    border-bottom: 1px solid #f2f2f2;

    .title {
      font-size: 22px;
      color: #2373ec;
      padding-top: 30px;
      padding-bottom: 10px;
      margin-left: 47px;
      width: 153px;
      border-bottom: 5px solid #2373ec;
      text-align: center;
    }
  }

  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 47px 51px 47px 39px;

    .btn {
      width: 165px;
      height: 43px;
      background: linear-gradient(90deg, #2272ec, #3a7bf6);
      box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
      border-radius: 5px;
      font-size: 19px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 38px;
    }
  }

  .bot-block {
    .box-top {
      display: flex;
      justify-content: space-around;
      align-items: center;

      .select {
        width: 97px;
        height: 30px;
        text-align: center;
        text-align-last: center;
      }
    }

    .box-bot {
      margin-top: 24px;
      text-align: right;
    }
  }

  .mid-bot {
    margin: 31px 13px 49px;
  }
</style>